import React, { memo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Typography } from '@mui/material'
import { addIdProp, addTestIdProp } from 'utils/universal'
import PostDynamicCard from './PostDynamicCard'
import sx from './styles'

const DynamicPosts = ({
  data = [],
  handleScroll,
  testId: testIdProp,
  shoGoToHomeButton = true,
  sxCard,
  sx: sxProp,
}) => {
  const { formatMessage: f } = useIntl()
  return (
    <Box>
      <Container
        maxWidth="lg"
        sx={{ ...sx.root, ...sxProp }}
        {...addTestIdProp(testIdProp)}
        {...addIdProp(testIdProp)}
      >
        <Grid
          container
          alignItems="end"
        >
          <Grid
            container
            sx={sx.contentCards}
          >
            {data && data.map((item, key) => (
              <Grid
                key={`internal-section-${key}`}
                item
                sx={sx.contentPost}
              >
                <PostDynamicCard
                  data={item}
                  sx={sxCard}
                />
              </Grid>
            ))}
          </Grid>
          {shoGoToHomeButton && (
            <Grid
              sx={sx.contentButton}
              mb={2}
            >
              <IconButton
                sx={sx.button}
                onClick={handleScroll}
                aria-label={f({ id: 'GO_TO_HOME' })}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <Typography
                variant="link-text"
                onClick={handleScroll}
                sx={sx.seeMore}
                mt={1}
              >
                <FormattedMessage id="GO_TO_HOME" />
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default memo(DynamicPosts)
