const styles = {
  buttonText: {
    width: '100%',
    marginY: 2,
  },

  textContainer: {
    paddingY: 2,
  },

  disclaimer: {
    backgroundColor: 'background.grey450',
    padding: 1,
  },

  timelineContainer: {
    borderRadius: 1,
    marginBottom: 2,
  },
}

export default styles
