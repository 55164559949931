import format from 'date-fns/format'
import es from 'date-fns/locale/es'
import { capitalize } from 'lodash'

export const dateToLocaleString = (date, options) => {
  return date.toLocaleString('es-MX', { ...options })
}

export const sameDay = (first, second) => first.getFullYear() === second.getFullYear()
  && first.getMonth() === second.getMonth()
  && first.getDate() === second.getDate()

export const addDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const formatDate = (date) => {
  const arrayDate = date.includes('/') ? date.split('/') : date.split('-')
  const dateSelected = new Date(
    parseInt(arrayDate[0]),
    parseInt(arrayDate[1] - 1),
    parseInt(arrayDate[2]),
  )
  const today = new Date()
  let text
  if (sameDay(dateSelected, today)) {
    text = 'Hoy,'
  } else if (sameDay(dateSelected, addDays(today, 1))) {
    text = 'Mañana,'
  } else {
    text = ''
  }
  return `${text} ${dateSelected.toLocaleString('es-MX', { weekday: 'long', day: 'numeric' })}`
}

export const getFormattedTime = (date) => {
  /*     return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/Mexico_City', // this is the only difference
  }) */
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes} ${ampm}`
  return strTime
}

export const convertTime12To24 = (time) => {
  if (!time) {
    return ''
  }
  let hours
  const timeArray = time?.split(':')
  hours = Number(timeArray[0])
  const minutes = timeArray[1].replace(' ', '')
  const cleanMinutes = minutes.toLowerCase().replace('am', '').replace('pm', '').replace(' ', '')
  if (time.toLowerCase().includes('pm')) {
    if (hours !== 12) {
      hours += 12
    }
  }
  return `${hours}:${cleanMinutes}:00`
}

const yearsDifference = (year) => {
  const currentYear = new Date().getFullYear()
  return currentYear === year ? 1 : currentYear - year
}

export const hideSellTodayOffer = (year, mileage, sellToDay) => {
  if (sellToDay > 700000) return true
  if (mileage > 150000) return true
  const years = yearsDifference(year)
  const mileageByYear = mileage / years
  return mileageByYear > 40000
}

export const formatFullDate = (date) => {
  if (!date) return
  return format(new Date(date), "d 'de' MMMM yyyy", { locale: es })
}

export const formatHour = (hour) => {
  if (!hour) return ''
  const parts = hour.split(':')
  let am = true
  const h = parseInt(parts[0], 10)

  if (h > 11) {
    am = false
  }
  return `${h === 12 ? h : h % 12}: ${parts[1]} ${am ? 'AM' : 'PM'}`
}

export const restYearsToDate = (years = 1) => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - years)
  return date
}

export const getDayName = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('es-MX', { weekday: 'short' })
  // new Date(dateString).toLocaleString('es_MX', { weekday: 'long' })
}

export const changeMiddleDashToSlash = (date) => {
  return date && typeof date === 'string' ? date.replace(/-/g, '/') : ''
}

export const humanizeDate = (date, finalHourCalendar) => {
  return `${capitalize(
    format(new Date(date), "EEEE dd 'de' MMMM yyyy", { locale: es }),
  )} ${date ? `a las ${getFormattedTime(finalHourCalendar)}` : ''}`
}

export const dateToDigExpSummary = (date, finalHourCalendar, location) => {
  return `${sameDay(new Date(), new Date(date)) ? 'Hoy, ' : ''}${capitalize(
    format(new Date(date), "EEEE dd 'de' MMMM yyyy", { locale: es }),
  )} ${date ? `a las ${getFormattedTime(finalHourCalendar)}` : ''} en ${`${location}`}`
}

export const converTwoDigitsHour = (time) => {
  if (!time) return
  return `${time}`?.split(':')[0]?.length === 1 ? `0${time}` : time
}

export const getDateAndTime = (date, time) => {
  return new Date(`${format(new Date(date), 'yyyy-MM-dd')}T${converTwoDigitsHour(time)}`)
}

export const formattedDate = (date, options) => {
  return new Date(`${date}`).toLocaleDateString('es-ES', options)
}

export const getDateToStatus = (date) => {
  return format(date, 'dd · MMM · yyyy', { locale: es })
}
