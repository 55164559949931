export const TESTIDS_HOME = {
  HOME_QUICKLINKS: 'HOME_QUICKLINKS',
  HOME_TESTIMONIALS_ONE: 'HOME_TESTIMONIALS_ONE',
  HOME_TESTIMONIALS_TWO: 'HOME_TESTIMONIALS_TWO',
  HOME_PROPOSALS: 'HOME_PROPOSALS',
  HOME_COVERAGE: 'HOME_COVERAGE',
  HOME_BLOG_POSTS: 'HOME_BLOG_POSTS',
  HOME_BUY_PROCESS: 'HOME_BUY_PROCESS',
  HOME_HERO_BANNER: 'HOME_HERO_BANNER',
  HOME_QUOTER: 'HOME_QUOTER',
  HOME_INTERNAL_SECTIONS: 'HOME_INTERNAL_SECTIONS',
  HOME_GOOGLE_REVIEWS: 'HOME_GOOGLE_REVIEWS',
  HOME_FAQS: 'HOME_FAQS',
  HOME_POSTS: 'HOME_POSTS',
}

export const TESTIDS_VDP = {
  VDP_I_AM_INTERESTED: 'VDP_I_AM_INTERESTED',
}

export const TESTIDS_BLOG = {
  BLOG_RELATED_POSTS: 'BLOG_RELATED_POSTS',
}

export const TESTIDS_HTB = {
  HTB_QUICKLINKS: 'HTB_QUICKLINKS',
  HTB_INTERNAL_SECTIONS: 'HTB_INTERNAL_SECTIONS',
  HTB_PROPOSALS: 'HTB_PROPOSALS',
  HTB_FAQS: 'HTB_FAQS',
  HTB_GOOGLE_REVIEWS: 'HTB_GOOGLE_REVIEWS',
}
