const styles = {
  root: {
  },

  mainContainer: {
    borderRadius: 0.5,
    paddingY: { xs: 2, lg: 3 },
    '&.MuiContainer-root': {
      padding: 0,
    },
    '& .MuiAutocomplete-root': {
      marginRight: '0px!important',
    },
  },

}

export default styles
