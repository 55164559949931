import React, { Fragment, memo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Timeline from 'components/common/Timeline'
import Requirements from 'components/common/Requirements'
import sx from './styles'

const CallToActionModal = ({
  data,
  disclaimer,
  modalType,
  pdfTitle = '',
  pdfUrl = '',
  content = '',
  variantTitle,
}) => {
  const isTimeline = modalType === 'timeline'
  return (
    <Grid
      container
      pt={isTimeline ? 2 : 0}
    >
      {content && (
        <Grid
          item
          xs={12}
          sx={sx.textContainer}
        >
          <Typography variant="body1">
            {content}
          </Typography>
        </Grid>
      )}
      {data && data.length > 0
        && data.map(({ text, timeline = false, disclaimer: disclaimerItem }) => (
          <Fragment key={text}>
            {text && (
              <Grid
                item
                xs={12}
                key={text}
                sx={sx.text}
                mt={3}
              >
                <Typography variant="h3">
                  {text}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              {Array.isArray(timeline) && (
                <Box sx={sx.timelineContainer}>
                  {isTimeline
                    ? (
                      <Timeline
                        data={timeline}
                        variant="vertical"
                      />
                    )
                    : (
                      <Requirements
                        data={timeline}
                        variantTitle={variantTitle}
                      />
                    )}
                </Box>
              )}
            </Grid>

            {/* item disclaimer  */}
            {disclaimerItem && (
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="body2"
                  sx={sx.disclaimer}
                >
                  {disclaimerItem}
                </Typography>
              </Grid>
            )}
          </Fragment>
        ))}
      {/* modal disclaimer  */}
      {disclaimer && (
        <Typography
          variant="body2"
          sx={sx.disclaimer}
        >
          {disclaimer}
        </Typography>
      )}
      {Boolean(pdfUrl && pdfTitle) && (
        <Grid
          item
          xs={12}
        >
          <Button
            fullWidth
            sx={sx.buttonText}
            component="a"
            variant="outlined"
            color="primary"
            href={pdfUrl}
            target="_blank"
            rel="noopener"
            aria-label={pdfTitle}
          >
            {pdfTitle}
          </Button>
        </Grid>
      )}

    </Grid>
  )
}

export default memo(CallToActionModal)
