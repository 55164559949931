const styles = {
  root: {
  },

  inputsContainer: {
    '&.row': {
      '& .MuiGrid-item': {
        paddingX: 1,
        marginTop: { xs: 1, lg: 0 },
        // '&:nth-of-type(1)': {
        //   marginTop: { xs: 1, lg: 0 },
        // },
      },
    },
    '&.column': {
      '& .MuiGrid-item': {
        marginTop: { xs: 1, lg: 0 },
      },
    },
  },
  titleContainer: {
    marginTop: 0,
    marginBottom: 2,
  },

  item: {
    paddingTop: { lg: '12px!important' },
    '& .MuiFormControl-root': {
      paddingY: 0,
      '& .MuiFormLabel-root': {
        fontSize: 12,
        color: 'text.grey300',
      },
      '&:placeholder': {
        fontSize: 12,
        color: '#000!important',
      },
    },
  },

  buttonContainer: {
    marginTop: { xs: 2, lg: 0 },
    display: 'flex',
    alignItems: 'end',
  },

  button: {
    '&.column': {
      marginTop: 1,
    },
  },

  field: {
    color: '#000!important',
    '& .MuiAutocomplete-inputRoot': {
      paddingY: '0px!important',
    },
    '& .MuiOutlinedInput-input': {
      paddingY: '12px!important',
    },
    '& ::-webkit-outer-spin-button': {
      display: 'none',
    },
    '& ::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: '0px!important',
    },
    '& .MuiInputBase-input': {
      padding: '9.5px 14px!important',
    },
    '&.MuiFormLabel-root': {
      color: '#343434!important',
    },
  },

  sliderContainer: {
    width: '90%',
  },

  slider: {
    margin: (theme) => theme.spacing(1, 'auto', 1, 'auto'),
    width: { xs: '100%' },
    display: 'block',

    '& .MuiSlider-track, .MuiSlider-rail': {
      height: 8,
      borderRadius: 8,
    },
    '& .MuiSlider-mark': {
      height: 8,
      display: 'none',
    },
    '& .MuiSlider-markLabel': {
      top: 45,
      color: 'text.grey200',
      // visibility: 'none',
    },
    '& .MuiSlider-valueLabel': {
      color: 'secondary.main',
      backgroundColor: 'transparent',
      // marginTop: { md: '20px' },
      width: { xs: 115, sm: 150 },
      height: { xs: 40, sm: 62 },
      textAlign: 'center',
      backgroundImage: 'url(/img/sliderValue.png)',
      backgroundSize: 'cover',
      backgroundPosition: { xs: 'center -4px' },
      backgroundRepeat: 'no-repeat',

      '& span': {
        fontSize: { xs: 14, sm: 16 },
        fontWeight: 600,
        backgroundColor: 'unset',
        left: 0,
        right: 0,
        display: 'unset',
      },
    },

    '& .MuiSlider-thumb': {
      height: 44,
      width: 44,
      boxShadow: (theme) => `
        inset 0 0 0 10px ${theme.palette.primary.main},
        inset 0 0 0 16px ${theme.palette.common.white}!important
      `,
    },
  },

  autocomplete: {
    '& .MuiAutocomplete-listbox': {
      '& .MuiAutocomplete-option:not(:last-of-type)': {
        borderBottom: '1px solid #ececec',
      },
    },
  },

  subtitle: {
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },

  mainTitle: {
    mt: { xs: 2, lg: 0 },
    mb: 1,
  },
}

export default styles
