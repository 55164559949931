/* eslint-disable camelcase */
import React, { memo } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useModal } from 'components/shared/Modal'
import Image from 'next/image'
import { useRouter } from 'next/router'
import CallToActionModal from 'components/common/CallToActionModal'
import sx from './styles'

const PostDynamicCard = (props) => {
  const { data, sx: sxProp } = props
  const { handleOpenModal } = useModal()
  const router = useRouter()
  const {
    title,
    description,
    textButton,
    image,
    urlType,
    cardModal,
    modalContent,
    modalTitle,
    modalDisclaimer,
    link,
    pdfUrl,
    modalType,
    pdfTitle,
  } = data
  const urlTypes = {
    modal: 'modal',
    internalUrl: 'internal_url',
  }

  const handleModal = () => {
    if (urlType === urlTypes.modal) {
      handleOpenModal({
        title: modalTitle,
        body: (
          <CallToActionModal
            data={cardModal}
            content={modalContent}
            href={pdfUrl}
            modalType={modalType}
            pdfTitle={pdfTitle}
            pdfUrl={pdfUrl}
            disclaimer={modalDisclaimer}
          />
        ),
        configProps: {
          isDividerHeader: true,
          showClose: true,
          scroll: 'body',
        },
      })
    } else if (urlType === urlTypes.internalUrl) {
      router.push(link)
    }
  }

  return (
    <Card sx={{ ...sx.root, sxProp }}>
      <CardContent sx={sx.cardContent}>
        <Typography
          variant="h3"
          sx={sx.title}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={sx.textDescription}
          mt={1}
        >
          {description}
        </Typography>
        <Typography
          variant="link-text"
          sx={sx.to}
          onClick={handleModal}
        >
          {textButton}
          {' '}
          {'>'}
        </Typography>
      </CardContent>
      <Container sx={sx.contentImage}>
        {image && (
          <Image
            src={image.url}
            alt={image.alt || title}
            layout="fill"
            objectFit="cover"
          />
        )}
      </Container>
    </Card>
  )
}

export default memo(PostDynamicCard)
