const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'background.grey500',
    boxShadow: 'none',
    height: 130,
  },
  cardContent: { width: '70%' },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },

  textDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },

  to: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  cardButtons: {
    width: '100%',
    fontSize: 12,
    color: '#000',
    border: '1px solid #E9E9E9',
    fontWeight: '500',
    mb: 2,
  },
  contentImage: {
    position: 'relative',
    width: '30%',
  },
}

export default styles
