import React, { memo } from 'react'
import Grid from '@mui/material/Grid'
import RequirementItem from './RequirementItem'
import sx from './styles'

const Requirements = ({ data, variantTitle }) => {
  return (
    <Grid
      container
      sx={sx.ulContainer}
    >
      {data.map((item, index) => (
        <Grid
          item
          xs={12}
          container
          key={`data-footer-${item.title}`}
        >
          <RequirementItem
            {...item}
            index={index}
            variantTitle={variantTitle}
          />
        </Grid>
      ))}

    </Grid>
  )
}

export default memo(Requirements)
