const styles = {
  root: {
    backgroundColor: 'background.default',
    paddingY: 3,
    marginTop: 1,
  },

  mainContainer: {
  },

  title: {
    color: 'text.primaryDark',
  },

  btn: {
    width: 50,
    height: 50,
    backgroundColor: 'primary.main',
    color: 'text.white',
  },
}

export default styles
