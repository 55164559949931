import React, { Fragment, memo } from 'react'
import Link from 'next/link'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { CardActions, Grid, useMediaQuery } from '@mui/material'
import useUtms from 'hooks/useUtms'
import Image from 'components/shared/Image'
import Text, { TEXT_TYPES } from 'components/designSystem/Text'
import { FormattedMessage } from 'react-intl'
import { formatFullDate } from 'utils/date'
import sx from './styles'

const PostCard = ({
  post,
  variant = 'vertical',
  sxProps = {},
  tagTopText,
  variantTitle = 'h4',
}) => {
  const {
    slug, title, date, description, featuredImage, tags,
  } = post
  const query = useUtms()
  const { title: titleImage, url = '' } = featuredImage
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const heightImage = isMobile ? 140 : 175
  const cardRow = variant === 'horizontal' ? sx.cardRow : {}
  const contentRow = variant === 'horizontal' ? sx.contentRow : {}
  return (
    <Card sx={{ ...sx.card, ...sxProps, ...cardRow }}>
      {url && (
        <Image
          src={url}
          alt={titleImage}
          title={titleImage}
          width={isMobile ? 207 : 320}
          height={heightImage}
          layout="fill"
          objectFit="contain"
          blurDataURL={url}
        />
      )}

      <CardContent sx={{ ...sx.contentCard, ...contentRow }}>
        {tagTopText && (
          <Grid
            item
            mb={1}
          >
            <Typography
              variant="quote"
              sx={sx.tagTopText}
            >
              {tagTopText}
            </Typography>
          </Grid>
        )}
        <Typography
          variant="body2"
          component="p"
          sx={sx.tags}
          mb={1}
        >
          {formatFullDate(date)}
          {tags.slice(0, 1).map((item, i) => (
            <Fragment key={i}>
              {' '}
              /
              {' '}
              {item.name}
            </Fragment>
          ))}
        </Typography>

        <Typography
          sx={sx.contentTitle}
          component={variantTitle}
          variant={variantTitle}
          mb={1}
        >
          <Link href={`/blog/${encodeURIComponent(slug)}${query}`}>
            {title}
          </Link>
        </Typography>

        <Text
          type={TEXT_TYPES.body.x1}
          sx={sx.contentDescription}
          mt={1}
        >
          {description}
        </Text>
      </CardContent>
      {!tagTopText && (
        <CardActions sx={sx.cardLink}>
          <Link
            href={`/blog/${encodeURIComponent(slug)}${query}`}
            passHref
          >
            <Typography
              variant="link-text"
              sx={sx.link}
              component="a"
            >
              <FormattedMessage id="READ_ARTICLE" />
            </Typography>
          </Link>
        </CardActions>
      )}
    </Card>
  )
}

export default memo(PostCard)
