import React, { memo } from 'react'
import PostsSwiper from 'components/BlogPage/PostsSwiper/PostsSwiper'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from 'next/link'
import { useIntl } from 'react-intl'
import useQueryString from 'hooks/useQueryString'
import { addIdProp, addTestIdProp } from 'utils/universal'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { IconButton, useMediaQuery } from '@mui/material'
import sx from './styles'

const Posts = (props) => {
  const { testId: testIdProp, posts, title = '' } = props
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const query = useQueryString()
  const { formatMessage: f } = useIntl()

  return (
    <Box
      sx={sx.root}
      {...addTestIdProp(testIdProp)}
      {...addIdProp(testIdProp)}
    >
      <Container
        maxWidth="lg"
        sx={sx.mainContainer}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h3"
              sx={sx.title}
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={11}
          >
            <PostsSwiper posts={posts} />
          </Grid>
          {isDesktop && (
            <Grid
              item
              xs="auto"
              mb={2}
            >
              <Box
                display="flex"
                justifyContent="center"
              >
                <Link
                  href={`/blog${query}`}
                  passHref
                >
                  <IconButton
                    sx={sx.btn}
                    aria-label={f({ id: 'SEE_MORE' })}
                  >
                    <ArrowForwardOutlinedIcon />
                  </IconButton>
                </Link>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default memo(Posts)
