const styles = {

  itemContainer: {
    marginBottom: 2,
    '&.body1': {
      marginBottom: 1,
    },
    alignItems: 'center',
  },

  dot: {
    color: '#343434',
    fontSize: 14,
    marginTop: 0.3,
    marginRight: 0.8,
    '&.body1': {
      fontSize: 9,
    },
  },

  subitem: {
    paddingLeft: 3,
    marginBottom: 1,
  },

  subtitle: {
    display: 'inline',
  },

  description: {
    marginTop: 0.5,
    marginLeft: 2,
  },

  bottomList: {
    fontWeight: 'normal',
    fontSize: '12px !important',
    lineHeight: '14px !important',
    color: 'text.grey300',
  },
}

export default styles
