import React, { memo } from 'react'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import sx from './styles'

const RequirementItem = (props) => {
  const {
    index,
    isDisclaimer,
    title: titleProp,
    description,
    variantTitle = 'h4',
    items,
  } = props

  return (
    <Grid
      item
      xs={12}
      px={2}
      sx={sx.itemContainer}
      container
      mt={index === 0 ? 2 : 0}
      className={clsx({
        body1: variantTitle === 'body1',
      })}
    >
      {!isDisclaimer
        && (
          <Grid
            item
            xs={1}
          >
            <FiberManualRecordIcon
              sx={sx.dot}
              className={clsx({
                body1: variantTitle === 'body1',
              })}
            />
          </Grid>
        )}

      <Grid
        item
        xs={11}
      >
        <Typography
          variant={variantTitle}
          sx={sx.title}
          xs={11}
        >
          {titleProp}
        </Typography>
      </Grid>

      {description && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="body1"
            sx={sx.description}
          >
            {description}
          </Typography>
        </Grid>
      )}
      {items && items.length > 0 && (
        <Box mt={2}>
          {items.map(
            (subitem, i) => (
              <Grid
                xs={12}
                item
                container
                sx={sx.subitem}
                key={`require-item-${i}`}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    variant={variantTitle}
                    xs={11}
                  >
                    {subitem?.title}
                  </Typography>
                </Grid>
              </Grid>
            ),
          )}
        </Box>
      )}

      {isDisclaimer && (
        <Typography
          variant="body2"
          sx={
            {
              ...sx.description,
              ...{ mt: -0.5 },
            }
          }
        >
          {titleProp}
        </Typography>
      )}
    </Grid>
  )
}

export default memo(RequirementItem)
