import gsx from 'styles'

const styles = {
  page: {
    backgroundColor: { xs: 'background.grey450', lg: 'background.default' },
  },
  // pageMobile: {
  //   backgroundColor: 'background.grey450',
  // },
  testimonialSwiper: {
    '& .MuiPaper-root': {
    },
  },

  googleReviewsContainer: {
    marginTop: 3,
    '& > .MuiGrid-container': {
      ...gsx.googleReviewsContainer,

    },
  },

  ratingContainer: {
    px: 0,
  },

  quoterContainer: {
    paddingX: { xs: '0px!important', lg: 'unset' },
    '& .MuiContainer-root': {
      padding: '0px!important',
    },
  },

  contactContainer: {
    paddingX: 2,
    marginBottom: 3,
    paddingY: '0px!important',
    backgroundColor: 'theme.default',
    '& > .MuiGrid-root': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '8px',
      paddingY: 2,
    },
    '& .MuiTypography-h3': {
      fontSize: '14px!important',
    },
    '& .MuiTypography-body2': {
      fontSize: '12px!important',
    },
  },

  timelineContainer: {
    '& .Timeline-Container-root': {
      paddingY: { lg: 6 },
      paddingX: { lg: 10 },
    },
  },

}

export default styles
