import React, {
  memo,
} from 'react'
import axios from 'axios'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import { useQuery } from 'react-query'
import Container from '@mui/material/Container'
import { cleanFilters, replaceValuesParams } from 'utils/filters'
import useUtms from 'hooks/useUtms'
import { parseFiltersToUrl } from 'filters'
import Form from './Form'
import sx from './styles'

const MakerAndPriceFilter = ({
  variantRow = 'row', // row|column
}) => {
  const router = useRouter()
  const { formatMessage: f } = useIntl()
  const filters = useSelector((s) => s.search.filters)
  const formattedParams = replaceValuesParams(cleanFilters(filters))

  const basePath = f({ id: 'ROUTE_SEARCH' })
  const query = useUtms()

  const metaFilters = useQuery(
    ['metaFilters', filters],
    async () => {
      const urlWithOutFilter = `${process.env.NEXT_PUBLIC_API_ROOT}/api/filters?all_inventory=true`
      const urlWitFilter = `${process.env.NEXT_PUBLIC_API_ROOT}/api/filters`
      const url = isEmpty(pickBy(filters)) ? urlWithOutFilter : urlWitFilter
      const req = await axios.get(
        url,
        { params: { ...formattedParams, extense_info: true } },
      )
      return req.data
    },
    {
      staleTime: 1000 * 60 * 24,
      keepPreviousData: true,
    },
  )

  const makers = metaFilters?.data?.makers

  const handleSubmit = (params) => {
    const url = parseFiltersToUrl(params)
    router.push(`${basePath}${url}${query}`)
  }

  return (
    <Box
      sx={sx.root}
    >
      <Container
        maxWidth="lg"
        sx={sx.mainContainer}
      >

        <Form
          makers={makers}
          variantRow={variantRow}
          onSubmit={handleSubmit}
          totalVehicles={metaFilters?.data?.used}
          loading={metaFilters?.status === 'loading'}
        />

      </Container>
    </Box>
  )
}

export default memo(MakerAndPriceFilter)
