import axios from 'axios'
import get from 'lodash/get'
import HomeApp from 'apps/Home'
import {
  testimonials,
  socialMediaSeoTagsHome,
  jsonLDHome,
} from 'vars' // TODO: Remove hardcoded data
import { getCommonStaticProps } from 'commonPage'
import commonMessages from 'lang/es/common'
import homeMessages from 'lang/es/home'
import { safePromise } from 'utils/universal/safePromise'
import { mapBlogPost } from 'mappers/blogMapper'
import { timelineAndVideoMapper, internalSectionMapper } from 'mappers/commonMapper'

export default HomeApp

export const getStaticProps = async () => {
  const commonStaticProps = await getCommonStaticProps()
  const messages = {
    ...commonMessages,
    ...homeMessages,
  }
  const url = `${process.env.NEXT_PUBLIC_API_WORDPRESS}/acf/v3/options/home`
  const { result } = await safePromise(axios(url))
  const mainBanner = {
    title: get(result, 'data.acf.title', ''),
    subtitle: get(result, 'data.acf.subtitle', ''),
    description: get(result, 'data.acf.description', ''),
    mainImage: get(result, 'data.acf.main_image', {}),
    buttons: get(result, 'data.acf.buttons', []),
    video_url: get(result, 'data.acf.video_url', ''),
    image_url: get(result, 'data.acf.image_url', null),
    disclaimer: get(result, 'data.acf.disclaimer', ''),
  }
  // eslint-disable-next-line no-unused-vars
  const internalSections = get(result, 'data.acf.internal_sections', [])
  const sycTimeline = get(result, 'data.acf.syc_timeline', {})
  const sycTimelineEasy = get(result, 'data.acf.syc_timeline_easy', {})
  const buyCarTimeline = get(result, 'data.acf.buyacar_timeline', {})
  const postsUrl = `${process.env.NEXT_PUBLIC_API_WORDPRESS}/wp/v2/posts?_embed&per_page=3&page=1`
  const { result: postsResult } = await safePromise(axios(postsUrl))
  const postsData = get(postsResult, 'data', [])
  const posts = postsData.map(mapBlogPost)

  return {
    props: {
      titleMeta: 'HOME_TITLE',
      descriptionMeta: 'HOME_DESCRIPTION',
      testimonials,
      messages,
      mainBanner,
      internalSections: internalSectionMapper(internalSections),
      buyCarTimeline: timelineAndVideoMapper(buyCarTimeline),
      sycTimeline: timelineAndVideoMapper(sycTimeline),
      sycTimelineEasy: timelineAndVideoMapper(sycTimelineEasy),
      posts,
      headerProps: {
        showBackButton: false,
        whatsappMessage: {
          id: 'HOME_WHATSAPP_MESSAGE',
        },
      },
      socialMediaTags: socialMediaSeoTagsHome,
      jsonLDData: jsonLDHome,
      ...commonStaticProps,
    },
  }
}
