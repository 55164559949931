const styles = {
  root: {
    marginY: 2,
    marginLeft: 'auto',
    width: { xs: '100%' },
    '& .swiper': {
      height: { xs: 360, lg: 420 },
    },
    '& .swiper-pagination': {
      order: 2,
      bottom: 10,
    },
    '& .swiper-pagination-bullet': {
      height: 5,
      width: { xs: 10, md: 70 },
      backgroundColor: '#E9E9E9!important',
      borderRadius: 'unset!important',
      margin: '1px!important',
    },
    '& .swiper-pagination-bullet-active': {
      backgroundColor: (theme) => `${theme.palette.primary.main}!important`,
    },

    '& .swiper-button-next, .swiper-button-prev': {
      zIndex: 600,
      bottom: 0,
      top: '96%',
    },
    '& .swiper .swiper-slide': {
      width: 'auto',
    },
  },

  postSwiper: {
    cursor: 'pointer',
  },

  btnContent: {
    height: '95%',
    display: 'flex',
    alignItems: 'center',
    pl: 2,
  },

  btn: {
    width: 50,
    height: 50,
    backgroundColor: 'primary.main',
    color: 'text.white',
  },
}

export default styles
