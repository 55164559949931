import React, { memo } from 'react'
import Link from 'next/link'
import { IconButton, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTheme } from '@mui/material/styles'
import PostCard from 'components/BlogPage/PostCard'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import sx from './styles'

const PostsSwiper = ({ posts = [] }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery((themes) => themes.breakpoints.down('sm'))
  const options = {
    slidesPerView: 'auto',
    freeMode: true,
    spaceBetween: 10,
    grabCursor: true,
    breakpoints: {
      [theme.breakpoints.values.lg]: {
        slidesPerView: 3,
        freeMode: false,
        spaceBetween: 18,
      },
    },
  }

  return (
    <Box sx={sx.root}>
      {Swiper && (
        <Swiper {...options}>
          {posts.map((item, i) => (
            <SwiperSlide key={i}>
              <PostCard
                key={i}
                post={item}
                sx={sx.postSwiper}
              />
            </SwiperSlide>
          ))}
          {isMobile && (
            <SwiperSlide>
              <Box sx={sx.btnContent}>
                <Link
                  href="/blog"
                  passHref
                >
                  <IconButton
                    sx={sx.btn}
                    aria-label="Ir al blog"
                  >
                    <ArrowForwardOutlinedIcon />
                  </IconButton>
                </Link>
              </Box>
            </SwiperSlide>
          )}
        </Swiper>
      )}
    </Box>
  )
}

export default memo(PostsSwiper)
